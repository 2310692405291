import React, { useEffect, useState } from 'react'
import StyledContainer from '../../../components/StyledContainer'
import { FaExpand } from 'react-icons/fa'
import { utils } from '../../../utils/utils'
import { SpinLoading } from '../../../assets/SpinLoading'

export const SalesPackageSeat = (({cell, seatMatrix, soldSeat, seatFocus, setSeatFocus, loading, toggleFullScreen, isFullScreen}) => {
  const [seat, setSeat] = useState([])
  const [seatLength, setSeatLength] = useState(0)
  const [restSeatLength, setRestSeatLength] = useState(0)
  const [orderList, setOrderList] = useState([])

  const SEAT_COLOR_TYPE = {
    NORMAL: {color: '#c5c5c5', value: 'normal', label: '1인석', order: 1},
    COUPLE: {color: '#D8565F', value: 'couple', label: '커플석', order: 2},
    SOLDOUT: {color: '#3D3F51', value: 'soldout', label: '판매좌석', order: 3},
    HOLDLOCK: {color: '#986140', value: 'holdlock', label: '보류좌석', order: 4}
  }

  const seatColor = (seat) => {
    if (!seat) return 'transparent'

    const {SeatStatus, SeatType} = seat
    const {NORMAL, COUPLE, SOLDOUT, HOLDLOCK} = SEAT_COLOR_TYPE

    const statusColor = {
      [SOLDOUT.value]: SOLDOUT.color,
      [HOLDLOCK.value]: HOLDLOCK.color
    }

    // 우선 SeatStatus로 체크
    if (statusColor[SeatStatus]) {
      return statusColor[SeatStatus]
    }

    const typeColor = {
      [NORMAL.value]: NORMAL.color,
      [COUPLE.value]: COUPLE.color
    }

    // SeatType 체크
    return typeColor[SeatType] || 'transparent'
  }

  const isSoldTicketCd = (ticketCd) => {
    return cell.find(r => r.code === ticketCd)
  }

  const getSeatStyle = (seat) => {
    const ticketCd = getSoldPackageCd(seat)

    return {
      cursor: ticketCd ? 'pointer' : 'default',
      backgroundColor: ticketCd ? isSoldTicketCd(ticketCd)?.color : seatColor(seat),
      padding: '4px',
      margin: '1px',
      textAlign: 'center',
      color: 'white',
      fontSize: isFullScreen ? '10px' : '11px',
      flex: isFullScreen ? '0 0 22px' : '0 0 62px',
      width: isFullScreen ? '22px' : '62px',
      height: isFullScreen ? '22px' : '50px',
      transform: 'translate3d(0, 0, 0)'
    }
  }

  const groupSeat = () => {
    const combinedArray = []

    seatMatrix.flat().forEach(cur => {
      const type = cur?.SeatType
      const status = cur?.SeatStatus

      // type과 status가 존재하고 각각 중복이 아닐 경우 추가
      if (type && !combinedArray.includes(type)) {
        combinedArray.push(type)
      }

      if (status && !combinedArray.includes(status)) {
        combinedArray.push(status)
      }
    })

    const sortedSeatTypes = combinedArray.sort((a, b) => {
      const orderA = Object.values(SEAT_COLOR_TYPE).find(item => item.value === a)?.order || Infinity
      const orderB = Object.values(SEAT_COLOR_TYPE).find(item => item.value === b)?.order || Infinity
      return orderA - orderB
    })

    setSeat(sortedSeatTypes)  // 중복 제거 후 배열 저장
  }

  const getSoldPackageCd = (seat) => {
    if (!seat?.SeatStatus) return

    const ticketCd = soldSeat.find(sold => sold?.SeatGroup === seat?.SeatGroup && sold?.SeatNo === seat?.SeatNo)?.TicketCd

    return ticketCd
  }

  const sumSoldSeatCnt = () => {
    const data = soldSeat?.reduce((acc, cur) => {
      if (cur?.TicketCd) {
        const {TicketCd} = cur
        acc[TicketCd] = acc[TicketCd] || []
        acc[TicketCd].push(cur)
      }
      return acc
    }, {})

    setOrderList(data)
  }

  const handleSeatFocus = (seat) => {
    if (seat?.SeatStatus !== 'soldout') return

    const TicketCd = getSoldPackageCd(seat)
    setSeatFocus(seatFocus === TicketCd ? null : TicketCd)
  }

  useEffect(() => {
    if (seatMatrix.flat().length > 0) {
      groupSeat()
      setSeatLength(seatMatrix.flat().filter(r => !!r))
      setRestSeatLength(seatMatrix.flat().filter(r => r?.SeatStatus === 'available'))
    }
  }, [seatMatrix])


  useEffect(() => {
    if (soldSeat?.length !== 0) {
      sumSoldSeatCnt()
    }
  }, [soldSeat])

  return (
    <StyledContainer margin={!isFullScreen}>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-700">
          좌석
        </div>
        {!loading && seatLength.length > 0 &&
          <p className="text-xs font-medium text-gray-400">
            <span className={'text-gray-500'}>남은 좌석 {restSeatLength?.length}</span> / 총 좌석 {seatLength?.length}
          </p>
        }
      </div>

      {loading ?
        <div
          className="flex items-center justify-center w-full h-36">
          <SpinLoading width={'w-10'} height={'h-10'} textColor={'text-gray-500'}/>
        </div>
        :
        <div className="bg-white w-full">
          <button className={`my-4 ml-auto flex justify-end`} onClick={toggleFullScreen}>
            <FaExpand size={14}/>
          </button>

          <div
            className={`flex justify-center items-center bg-gray-100 px-4 py-2 rounded`}
          >
            <div className="p-2 overflow-auto">
              {seatMatrix.map((row, rowIndex) => (
                <div key={rowIndex} className={'flex'}>
                  {row.map((seat, colIndex) => (
                    <button key={colIndex}
                            style={getSeatStyle(seat)}
                            className={`flex justify-center items-center rounded-sm ${seatFocus === getSoldPackageCd(seat) && 'animate-pulse'}`}
                            onClick={() => handleSeatFocus(seat)}
                    >
                      {seat &&
                        <p className={'flex flex-col'} style={{width: 'inherit'}}>
                          <span className={'text-white'}>
                            {seat.SeatGroup + seat.SeatNo}
                          </span>
                          {!isFullScreen &&
                            <span className={`text-yellow-100 font-bold whitespace-nowrap overflow-hidden text-ellipsis`}>
                              {utils.SOLD_TICKETCD[getSoldPackageCd(seat)]}
                            </span>
                          }
                        </p>
                      }
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="text-xs font-medium text-gray-700 flex items-center justify-center mt-6 gap-4">
            {Object.keys(orderList)?.map((ticketCd, idx) => (
              <p key={ticketCd} className={'flex items-center'}>
                      <span className={`inline-block rounded-full mr-1 w-1.5 h-1.5`}
                            style={{background: isSoldTicketCd(ticketCd)?.color}}
                      />
                {isSoldTicketCd(ticketCd)?.title}
              </p>
            ))}
          </div>
        </div>
      }
    </StyledContainer>
  )
})