import { format, isBefore } from 'date-fns'

export const utils = {
  COUPON_CHECK_TYPE: {
    NONE: '', DUPLICATE: '중복된 쿠폰입니다.', FAIL: '등록되지 않은 쿠폰입니다.', SUCCESS: '사용 가능한 쿠폰입니다.', ENG: '영어로 입력해야 합니다.'
  },
  SOLD_TICKETCD: {
    '002451': 'B_PKG-1', // 베이직 패키지 1 : 컵과일
    '002452': 'B_PKG-2', // 베이직 패키지 2 : 디저트 3종
    '002453': 'PKG-1', // 패키지 1 : 치맥세트
    '002454': 'PKG-2-R', // 패키지 2 : 레드와인
    '002459': 'PKG-2-W', // 패키지 2 : 화이트와인
    '002460': 'NORMAL' // 일반
  },
  preventScroll() {
    const currentScrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
    document.body.style.top = `-${currentScrollY}px` // 현재 스크롤 위치
    document.body.style.overflowY = 'scroll'
    return currentScrollY
  },
  allowScroll() {
    document.body.style.position = ''
    document.body.style.width = ''
    document.body.style.top = ''
    document.body.style.overflowY = ''
  },
  hasKoreanText(text) {
    return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text)
  },
  isTimeBefore(row) {
    const currentDate = new Date()
    const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm')
    return isBefore(formattedDate, `${row.PlaySDT} ${row.StartTime}`)
  },
  hasDuplicateByKey(coupon, key) {
    if (coupon?.length > 1) {
      return coupon?.some((r, idx) => r?.code === coupon[key]?.code && idx !== key)
    }
  },
}