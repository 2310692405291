// StyledContainer.js
import React from 'react';

const StyledContainer = ({ children, margin = true }) => {
  return (
    <div className={`bg-white rounded p-5 ${margin && 'mx-4 mt-3'} shadow-sm flex flex-col flex-grow`}>
      {children}
    </div>
  );
};

export default StyledContainer;