import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { getMonth, getYear } from 'date-fns'
import React from 'react'

export const DatePickerHeader = ({
  date,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseMonth,
  increaseMonth
}) => {

  const arrowClick = (e, type) => {
    e.preventDefault()
    return type === 'PREV' ? decreaseMonth() : increaseMonth()
  }

  return (
    <div
      className={'m-2.5 flex justify-between items-center'}
    >
      <button
        onClick={(e) => arrowClick(e, 'PREV')}
        disabled={prevMonthButtonDisabled}
        className={'disabled:text-gray-400 disabled:cursor-not-allowed'}
      >
        <IoIosArrowBack className={'w-8 h-8'}/>
      </button>

      <div className="text-2xl font-bold">
        {getYear(date)}.{getMonth(date) + 1}
      </div>

      <button
        onClick={(e) => arrowClick(e, 'NEXT')}
        disabled={nextMonthButtonDisabled}
        className={'disabled:text-gray-400 disabled:cursor-not-allowed'}
      >
        <IoIosArrowForward className={'w-8 h-8'}/>
      </button>
    </div>
  )
}
