import StyledContainer from '../../../components/StyledContainer'
import React from 'react'

export const Gender = ({handleInputChange}) => {
  return (
    <StyledContainer>

      <div className="text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
        성별
      </div>

      <fieldset>
        <div className="mt-3 space-y-2">
          <label
            htmlFor="Option1"
            className="flex cursor-pointer items-center gap-4 rounded-lg border border-gray-200 px-4 py-3 transition hover:bg-gray-50 has-[:checked]:bg-gray-50"
          >
            <input type="radio"
                   required
                   className="text-black bg-gray-50 border-gray-300 focus:ring-black focus:ring-1 size-4"
                   id="Option1"
                   name="gender" value="남성"
                   onChange={handleInputChange}/>

            <strong className="font-medium text-gray-900 text-[14px]"> 남성 </strong>
          </label>

          <label
            htmlFor="Option2"
            className="flex cursor-pointer items-center gap-4 rounded-lg border border-gray-200 px-4 py-3 transition hover:bg-gray-50 has-[:checked]:bg-gray-50"
          >
            <input type="radio"
                   className="text-black bg-gray-50 border-gray-300 focus:ring-black focus:ring-1 size-4" id="Option2"
                   name="gender" value="여성"
                   onChange={handleInputChange}/>
            <strong className="font-medium text-gray-900 text-[14px]"> 여성 </strong>
          </label>
        </div>
      </fieldset>
    </StyledContainer>
  )
}