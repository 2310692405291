import { ReactComponent as MonoplexLogo } from '../../assets/monoplex_logo.svg'
import React from 'react'
import { FaTriangleExclamation } from 'react-icons/fa6'

export const NotFound =() => {
  return (
    <div className={'w-screen h-screen flex flex-col items-center justify-center gap-4'}>
      <div className={'flex items-center gap-2 pb-4 border-b border-gray-400'}>
        <FaTriangleExclamation size={30}/> <h1 className={'text-2xl'}>페이지를 찾을 수 없습니다.</h1>
      </div>
      <MonoplexLogo width={'100'} fill={'black'} className={''}/>
    </div>
  )
}