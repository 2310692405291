import ajax from './ajax'

const dtryxSeatHistoryApiUrl = process.env.REACT_APP_DTRYX_SEAT_HISTORY_API_URL
const dtryxSeatListApiUrl = process.env.REACT_APP_DTRYX_SEAT_LIST_API_URL
const dtryxSalesSeatListApiUrl = process.env.REACT_APP_DTRYX_SALES_SEAT_LIST_API_URL
const dtryxMovieDetailApiUrl = process.env.REACT_APP_DTYRX_MOVIE_DETAIL_API_URL
const dtryxScheduleListApiUrl = process.env.REACT_APP_DTRYX_SCHEDULE_LIST_API_URL

const ryseCd = '000155'


export const seat = {
  scheduleList(date, CinemaCd = ryseCd) {
    return ajax.get(dtryxScheduleListApiUrl, {PlaySDTFrom: date, PlaySDTTo: date, CinemaCd})
  },
  seatHistory(date, CinemaCd = ryseCd) {
    return ajax.get(dtryxSeatHistoryApiUrl, {PlaySDTFrom: date, PlaySDTTo: date, CinemaCd})
  },
  seatList(ScreenCd, PlaySDT, ShowSeq, CinemaCd = ryseCd) {
    return ajax.get(dtryxSeatListApiUrl, {ScreenCd, PlaySDT, ShowSeq, CinemaCd})
  },
  salesSeatList(payload) {
    const {PlaySDT, ScreenCd, ShowSeq, SeatGroup, SeatNo, CinemaCd = ryseCd} = payload

    return ajax.get(dtryxSalesSeatListApiUrl, {PlaySDT, ScreenCd, ShowSeq, SeatGroup, SeatNo, CinemaCd})
  },
  movieDetail(MovieCd, CinemaCd = ryseCd) {
    return ajax.get(dtryxMovieDetailApiUrl, {CinemaCd, MovieCd, ImgSize: 'small'})
  }
}
