import React from 'react'
import package1 from '../../../assets/img/ryse-package-1.png'
import package2 from '../../../assets/img/ryse-package-2.png'

export const Package = () => {

  return (
    <div className={'w-full md:max-w-md bg-gray-400 rounded px-5 py-4 mb-7'}>
      <div className="text-sm font-medium text-white mb-3 flex flex-col">
        <span>F&B 패키지 선택 (택 1)</span>
        <span className={'mt-1 text-xs text-gray-200'}>*쿠폰 조회 후 선택</span>
      </div>

      <div className="flex flex-col xs:flex-row items-center gap-4">
        <div className={'rounded-lg w-full'}>
          <img className="rounded-t w-full"
               src={package1}
               alt="product package1"/>
          <div className="rounded-b px-4 py-2.5 bg-white text-gray-500">
            <p className="font-medium text-gray-900 text-[14px] flex flex-col gap-0.5">
              <span>베이직 패키지 1</span>
              <span className={'text-xs text-gray-500'}>(컵과일+물)</span>
            </p>
          </div>
        </div>

        <div className={'rounded-lg w-full'}>
          <img className="rounded-t w-full"
               src={package2}
               alt="product package2"/>
          <div className="rounded-b px-4 py-2.5 bg-white text-gray-500">
            <p className="font-medium text-gray-900 text-[14px] flex flex-col gap-0.5">
              <span>베이직 패키지 2</span>
              <span className={'text-xs text-gray-500'}>(디저트 3종+물)</span>
            </p>
          </div>
        </div>
      </div>
      <div className={'flex text-xs text-gray-200 gap-1 mt-3 break-keep'}>
        ※ 관람권에 포함되어 있는 상품이며, 추가 결제 금액은 발생하지 않습니다.
      </div>
    </div>

  )
}