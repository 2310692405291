import React, { Fragment, useEffect, useState } from 'react'
import StyledContainer from '../../../components/StyledContainer'

export const Seat = ({seatMatrix}) => {
  const [seat, setSeat] = useState([])
  const [seatLength, setSeatLength] = useState(0)
  const [restSeatLength, setRestSeatLength] = useState(0)

  const SEAT_COLOR_TYPE = {
    NORMAL: {color: '#56b5d8', value: 'normal', label: '1인석', order: 1},
    COUPLE: {color: '#D8565F', value: 'couple', label: '커플석', order: 2},
    SOLDOUT: {color: '#3D3F51', value: 'soldout', label: '판매좌석', order: 3},
    HOLDLOCK: {color: '#986140', value: 'holdlock', label: '보류좌석', order: 4}
  }

  const seatColor = (seat) => {
    if (!seat) return 'transparent'

    const {SeatStatus, SeatType} = seat
    const {NORMAL, COUPLE, SOLDOUT, HOLDLOCK} = SEAT_COLOR_TYPE

    const statusColor = {
      [SOLDOUT.value]: SOLDOUT.color,
      [HOLDLOCK.value]: HOLDLOCK.color
    }

    // 우선 SeatStatus로 체크
    if (statusColor[SeatStatus]) {
      return statusColor[SeatStatus]
    }

    const typeColor = {
      [NORMAL.value]: NORMAL.color,
      [COUPLE.value]: COUPLE.color
    }

    // SeatType 체크
    return typeColor[SeatType] || 'transparent'
  }

  const getSeatStyle = (seat) => ({
    backgroundColor: seatColor(seat),
    // padding: '8px',
    margin: '1px',
    textAlign: 'center',
    fontSize: '10px',
    color: 'white',
    flex: '0 0 24px',
    width: '24px',
    height: '24px'
  })


  const groupSeat = () => {
    const combinedArray = []

    seatMatrix.flat().forEach(cur => {
      const type = cur?.SeatType
      const status = cur?.SeatStatus

      // type과 status가 존재하고 각각 중복이 아닐 경우 추가
      if (type && !combinedArray.includes(type)) {
        combinedArray.push(type)
      }

      if (status && !combinedArray.includes(status)) {
        combinedArray.push(status)
      }
    })

    const sortedSeatTypes = combinedArray.sort((a, b) => {
      const orderA = Object.values(SEAT_COLOR_TYPE).find(item => item.value === a)?.order || Infinity;
      const orderB = Object.values(SEAT_COLOR_TYPE).find(item => item.value === b)?.order || Infinity;
      return orderA - orderB;
    });

    setSeat(sortedSeatTypes)  // 중복 제거 후 배열 저장
  }

  useEffect(() => {
    if (seatMatrix.flat().length > 0) {
      groupSeat()
      setSeatLength(seatMatrix.flat().filter(r => !!r))
      setRestSeatLength(seatMatrix.flat().filter(r => r?.SeatStatus === 'available'))
    }
  }, [seatMatrix])

  return (
    <StyledContainer>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-700">
          좌석 <span className={'text-xs text-gray-500'}>(랜덤 배정)</span>
        </div>
        {seatLength.length > 0 &&
          <p className="text-xs font-medium text-gray-400">
            <span className={'text-gray-500'}>남은 좌석 {restSeatLength?.length}</span> / 총 좌석 {seatLength?.length}
          </p>
        }
      </div>

      <div className="mt-5 flex justify-center items-center w-full bg-gray-50 px-4 py-2 rounded">
        <div className="p-2 overflow-auto">
          {seatMatrix.map((row, rowIndex) => (
            <div key={rowIndex} className={'flex'}>
              {row.map((seat, colIndex) => (
                <div key={colIndex} style={getSeatStyle(seat)} className={'flex justify-center items-center rounded-sm'}>
                  {seat ? `${seat.SeatGroup}${seat.SeatNo}` : ''}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="text-xs font-medium text-gray-700 flex items-center justify-center mt-6 gap-4">
        {seat?.map((seat, idx) => (
          <Fragment key={seat}>
            {SEAT_COLOR_TYPE[seat.toUpperCase()]?.label &&
              <p className={'flex items-center'}>
                <span className={`inline-block rounded-full mr-1 w-1.5 h-1.5`} style={{background: SEAT_COLOR_TYPE[seat.toUpperCase()]?.color}}/>
                {SEAT_COLOR_TYPE[seat.toUpperCase()]?.label}
              </p>
            }
          </Fragment>
        ))}

      </div>
    </StyledContainer>
  )
}