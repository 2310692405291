import React from 'react'
import { ReactComponent as MonoplexLogo } from '../assets/monoplex_logo.svg'

export const Footer = () => {
  return (
    <footer className="text-center text-gray-500 mb-[30px] flex flex-col gap-4">
      <div className={'text-xs'}>
        <p className={'underline'}>
          문제가 있으신가요?
        </p>
        <p className={'mt-1'}>
          1577-0723에 문의 부탁드립니다.
        </p>
      </div>
      <MonoplexLogo width={'80'} fill={'#818181'} className={'m-auto'}/>
    </footer>
  )
}