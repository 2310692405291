import StyledContainer from '../../../components/StyledContainer'
import React, { Fragment, useEffect, useState } from 'react'
import { SpinLoading } from '../../../assets/SpinLoading'

export const OrderState = ({cell, soldSeat, seatFocus, setSeatFocus, isFullScreen, loading}) => {
  const [orderList, setOrderList] = useState([])
  const [totalLength, setTotalLength] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)


  const sumSoldSeatCnt = () => {
    let totalLen = 0
    let totalAmt = 0
    const data = soldSeat?.reduce((acc, cur) => {
      if (cur?.TicketCd) {
        const {TicketCd, TotalAmt} = cur
        acc[TicketCd] = acc[TicketCd] || []
        acc[TicketCd].push(cur)
        totalLen += 1
        totalAmt += parseInt(TotalAmt)
      }
      return acc
    }, {})

    setOrderList(data)
    setTotalLength(totalLen.toLocaleString())
    setTotalAmount(totalAmt.toLocaleString())
  }

  const getSetColor = (r) => {
    const pointer = !!orderList[r.code]?.length && 'cursor-pointer'
    const focus = seatFocus === r.code ? `animate-pulse` : ``
    return `${pointer} ${focus}`
  }

  const getCellStyle = (r, head = false) => {
    const baseColor = '#1f2937'
    const isFocused = seatFocus === r.code

    return {
      color: head ? r.color : (baseColor),
      background: isFocused ? `${r.color}10` : ''
    }
  }

  const isSeatFocus = seatFocus ? cell.find(r => r.code === seatFocus) : undefined


  const handleSeatFocus = (code) => {
    if (!isNaN(code) && orderList[code]?.length) {
      setSeatFocus(prev => (prev === code ? null : code))
    }
  }

  useEffect(() => {
    if (soldSeat?.length !== 0) {
      sumSoldSeatCnt()
    }
  }, [soldSeat])

  return (
    <StyledContainer margin={!isFullScreen}>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-700">
          오더 현황
        </div>
        {cell.find(r => r.code === seatFocus) &&
          <p className={'text-xs text-gray-400'}>
            선택한 좌석 패키지 : <span style={{color: isSeatFocus ? isSeatFocus.color : 'gray'}}>{isSeatFocus?.title}</span>
          </p>
        }
      </div>


      {loading ?
        <div
          className="flex items-center justify-center w-full h-36">
          <SpinLoading width={'w-10'} height={'h-10'} textColor={'text-gray-500'}/>
        </div>
        :

        <div className="mt-4 overflow-x-auto rounded-lg border border-gray-200">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="ltr:text-left rtl:text-right text-center bg-gray-50">
            <tr>
              <th className="sticky inset-y-0 start-0 bg-gray-50 px-3 py-2">
              </th>
              {cell.map((r, id) => (
                <Fragment key={r.code}>
                  {orderList[r.code] &&
                    <th key={r.title}
                        className={`whitespace-nowrap px-4 py-2 font-medium text-xs text-gray-900 ${getSetColor(r)}`}
                        style={getCellStyle(r, true)}
                        onClick={() => handleSeatFocus(r.code)}
                    >
                      <p className={`flex flex-col gap-0.5`}>
                        <span>{r.title}</span>
                        <span className={'text-[10px]'}>({r.sub})</span>
                      </p>
                    </th>
                  }
                  {r.code === 'TOTAL' && <th className={'whitespace-nowrap px-4 py-2 font-medium text-xs text-gray-900'}>{r.title}</th>}
                </Fragment>
              ))}
            </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
            <tr className={`text-center bg-white text-xs `}>
              <td className={`sticky inset-y-0 start-0 bg-white px-3 py-2 whitespace-nowrap`}>
                수량
              </td>
              {cell.map(r =>
                <Fragment key={r.code}>
                  {orderList[r.code] &&
                    <td key={r.title}
                        className={`whitespace-nowrap px-4 py-2 text-gray-800 ${getSetColor(r)}`}
                        style={getCellStyle(r)}
                        onClick={() => handleSeatFocus(r.code)}
                    >
                      {orderList[r.code]?.length || '-'}
                    </td>
                  }
                  {r.code === 'TOTAL' && <td className={'whitespace-nowrap px-4 py-2 text-gray-800'}>{totalLength}</td>}
                </Fragment>
              )}
            </tr>

            <tr className={`text-center bg-white text-xs`}>
              <td className={`sticky inset-y-0 start-0 bg-white px-3 py-2 whitespace-nowrap`}>
                총합
              </td>
              {cell.map(r =>
                <Fragment key={r.code}>
                  {orderList[r.code] &&
                    <td key={r.title}
                        className={`whitespace-nowrap px-4 py-2 text-gray-800 ${getSetColor(r)}`}
                        style={getCellStyle(r)}
                        onClick={() => handleSeatFocus(r.code)}
                    >
                      {(orderList[r.code]?.length * parseInt(orderList[r.code][0]?.TotalAmt))?.toLocaleString() || '-'}
                    </td>
                  }
                  {r.code === 'TOTAL' && <td className={'whitespace-nowrap px-4 py-2 text-gray-800'}>{totalAmount}</td>}
                </Fragment>
              )}
            </tr>
            </tbody>
          </table>
        </div>
      }
    </StyledContainer>
  )
}