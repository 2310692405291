import React, { useEffect, useState } from 'react'
import { seat } from '../../api/dytrxApi'
import { registerLocale } from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import { format } from 'date-fns'
import { SpinLoading } from '../../assets/SpinLoading'
import { Seat } from './components/Seat'
import { ScheduleTable } from '../../components/schedule/ScheduleTable'
import { Coupon } from './components/Coupon'
import { Gender } from './components/Gender'
import { Name } from './components/Name'
import { Contact } from './components/Contact'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { SubmitButton } from './components/SubmitButton'
import { table } from '../../api/airTableAPi'
import { utils } from '../../utils/utils'
import { slack } from '../../api/slackNotifyApi'
import { SelectMovie } from './components/selectProduct/SelectMovie'
import { Helmet } from 'react-helmet-async'

export const Reservation = () => {
  registerLocale('ko', ko) //날짜 한국어로 표시

  const [seatMatrix, setSeatMatrix] = useState([])
  const [list, setList] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    phoneNumber: '',
    couponCode: [{id: null, code: null, status: null, basicPackage: null}],
    date: new Date(),
    screen: '',
    imgUrl: '',
    movie: {}
  })

  // 스케줄
  const getDtryxHistoryListApi = () => {
    setFormData(prev => ({
      ...prev,
      movie: {}
    }))

    const {date} = formData
    if (date) {
      const formatDate = format(new Date(date), 'yyyy-MM-dd')
      seat.scheduleList(formatDate).then(list => {
        const filter = list.Recordset.filter(r => r.PlanStatus === 'confirm') // 확정된 스케줄만 노출
        setList(filter)
      })
    }
  }

  // 좌석 리스트
  const getDtryxSeatListApi = () => {
    const {movie} = formData
    if (movie.MovieCd) {
      seat.seatList(movie.ScreenCd, movie.PlaySDT, movie.ShowSeq).then(r => {
        const maxRow = Math.max(...r.Recordset.map(seat => Number(seat.LocationRow)))
        const maxCol = Math.max(...r.Recordset.map(seat => Number(seat.LocationCol)))
        setSeatMatrix(createSeatMatrix(r.Recordset, maxRow, maxCol))
      })
    }
  }

  const createSeatMatrix = (seat, maxRow, maxCol) => {
    const matrix = Array.from({length: maxRow}, () => Array(maxCol).fill(null))
    // 좌석 데이터를 배열에 배치
    seat.forEach(seat => {
      const row = Number(seat.LocationRow) - 1
      const col = Number(seat.LocationCol) - 1
      matrix[row][col] = seat
    })

    return matrix
  }

  const handleInputChange = (event, idx) => {
    const targets = Array.isArray(event.target) ? event.target : [event.target]

    setFormData((prev) => {
      const updatedFormData = {...prev}

      targets.forEach(({name, value, id}) => {
        let updatedValue

        if (name === 'couponCode') {
          let newCouponCode = Array.isArray(prev.couponCode) ? [...prev.couponCode] : []

          if (id !== 'selectPackage') {
            newCouponCode[idx] = {
              id: null,
              code: value.toUpperCase(),
              status: utils.hasKoreanText(value) ? utils.COUPON_CHECK_TYPE.ENG : utils.COUPON_CHECK_TYPE.NONE,
              basicPackage: null
            }
          } else {
            newCouponCode[idx] = {
              ...newCouponCode[idx],
              basicPackage: value
            }
          }

          updatedValue = newCouponCode
        } else if (name === 'movie') {
          updatedValue = JSON.parse(value)
        } else {
          updatedValue = value
        }

        updatedFormData[name] = updatedValue
      })

      return updatedFormData
    })
  }

  // 제출
  const handleSubmit = async (event) => {
    event.preventDefault()
    await submitCoupon()
  }

  const submitCoupon = async () => {
    const {name, gender, couponCode, phoneNumber, movie: {MovieNm, PlaySDT, StartTime}} = formData

    if (utils.hasDuplicateByKey(couponCode, 'code')) {
      return alert('동일한 쿠폰을 입력할 수 없습니다.')
    }

    // 현재 시간이 예매 시간을 경과한 경우
    if (!utils.isTimeBefore(formData?.movie)) {
      alert('현재 시간이 예매 시간을 지나 제출할 수 없습니다.')
      return window.location.reload()
    }

    // 등록된 쿠폰 아닐 때
    if (!couponCode.every(r => r.status === utils.COUPON_CHECK_TYPE.SUCCESS)) {
      return alert('제출이 정상적으로 되지 않았습니다.')
    }

    const formatDateTime = (PlaySDT, StartTime) => {
      return `${PlaySDT}T${StartTime}:00Z`
    }

    const payload = (idx) => ({
      Code: couponCode[idx].code,
      Gender: gender,
      Name: name,
      Contact: phoneNumber,
      Movie: MovieNm,
      Package: couponCode[idx].basicPackage,
      Date: formatDateTime(PlaySDT, StartTime),
      Seat: ''
    })

    // 사용된 쿠폰 used 체크
    const patchCouponUsedCheck = async (idx) => {
      try {
        await table.usedCouponCheck(couponCode[idx], couponCode[idx].id)
      } catch (error) {
        console.error('Error updating coupon:', error)
        throw error // 에러를 다시 던져서 호출한 곳에서 처리
      }
    }

    // payload 로그 출력
    const promises = couponCode.map(async (_, idx) => {
      const data = payload(idx)
      await patchCouponUsedCheck(idx)
      await table.postCoupon(data)
      return data
    })

    try {
      utils.preventScroll()
      setSubmitLoading(true)
      await Promise.all(promises).then((dataArray) => {
        slack.postNotify(dataArray)
      })
      alert('제출이 완료되었습니다.')
    } catch (error) {
      console.error('Error submitting coupons:', error)
    } finally {
      utils.allowScroll()
      setSubmitLoading(false)
      window.location.reload()
    }
  }

  useEffect(() => {
    getDtryxHistoryListApi()
  }, [formData?.date])

  useEffect(() => {
    getDtryxSeatListApi()
  }, [formData?.movie?.StartTime])

  return (
    <>
      <Helmet>
        <title>MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록</title>
        <meta name="description" content="MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록"/>
        <meta property="og:title" content="MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록"/>
        <meta property="og:description" content="MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록"/>
      </Helmet>

      <main className="relative mt-4 bg-gray-200">
        <div className="xl:w-[840px] w-full m-auto h-full rounded pb-[70px]">
          <Header/>

          <form onSubmit={handleSubmit} className="mt-6 h-full">
            <Gender handleInputChange={handleInputChange}/>

            <Name handleInputChange={handleInputChange}/>

            <Contact formData={formData} handleInputChange={handleInputChange}/>

            <Coupon formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
            />

            <ScheduleTable formData={formData} setFormData={setFormData} list={list} handleInputChange={handleInputChange}/>

            {formData?.movie?.MovieNm &&
              <>
                <SelectMovie formData={formData}/>
                <Seat seatMatrix={seatMatrix}/>
              </>
            }

            <SubmitButton formData={formData}
                          submitLoading={submitLoading}
            />
          </form>

        </div>

        <Footer/>

        {submitLoading &&
          <div className={'fixed left-0 top-0 bottom-0 right-0 bg-black/50 z-10 flex items-center justify-center'}>
            <SpinLoading width={'w-10'} height={'h-10'} textColor={'text-white'}/>
          </div>
        }
      </main>
    </>
  )
}