import React, { useEffect, useState } from 'react'
import { seat } from '../../api/dytrxApi'
import { registerLocale } from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import { format } from 'date-fns'
import { ScheduleTable } from '../../components/schedule/ScheduleTable'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { utils } from '../../utils/utils'
import { SalesPackageSeat } from './components/SalesPackageSeat'
import { OrderState } from './components/OrderState'
import { Helmet } from 'react-helmet-async'

export const Sales = () => {
  registerLocale('ko', ko) //날짜 한국어로 표시

  const [seatMatrix, setSeatMatrix] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [seatFocus, setSeatFocus] = useState(null)
  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    phoneNumber: '',
    couponCode: [{id: null, code: null, status: null, basicPackage: null}],
    date: new Date(),
    screen: '',
    imgUrl: '',
    movie: {}
  })
  const [soldSeat, setSoldSeat] = useState([])

  const cell = [
    {title: 'B_PKG-1', label: '베이직 패키지 1', sub: '컵과일', color: '#72ab1e'},
    {title: 'B_PKG-2', label: '베이직 패키지 2', sub: '디저트 3종', color: '#d8569b'},
    {title: 'PKG-1', label: '패키지 1', sub: '치맥세트', color: '#c77f21'},
    {title: 'PKG-2-R', label: '패키지 2-R', sub: '레드와인 & 샤키테리세트', color: '#750000'},
    {title: 'PKG-2-W', label: '패키지 2-W', sub: '화이트와인 & 샤키테리세트', color: '#65ab8e'},
    {title: '합계', label: 'TOTAL'}
  ]

  cell.forEach(r => {
    r.value = r.title // value 추가

    const ticketCodes = Object.keys(utils.SOLD_TICKETCD).filter(
      key => utils.SOLD_TICKETCD[key] === r.title
    )
    r.code = ticketCodes[0] || 'TOTAL'
  })


  // 스케줄
  const getDtryxHistoryListApi = () => {
    setFormData(prev => ({
      ...prev, movie: {}
    }))

    const {date} = formData
    if (date) {
      const formatDate = format(new Date(date), 'yyyy-MM-dd')
      seat.scheduleList(formatDate).then(list => {
        const filter = list.Recordset.filter(r => r.PlanStatus === 'confirm') // 확정된 스케줄만 노출
        setList(filter)
      })
    }
  }

  const getDtryxSalesSeatListApi = async () => {
    const {movie: {PlaySDT, ScreenCd, ShowSeq}} = formData

    // 판매완료인 것들만 filter
    const soldSeat = seatMatrix.flat().filter(seat => seat?.SeatStatus === 'soldout')

    const createPayload = (seat) => ({
      PlaySDT,
      ScreenCd,
      ShowSeq,
      SeatGroup: seat?.SeatGroup,
      SeatNo: seat?.SeatNo
    })

    // API 호출 및 상태 업데이트 함수
    const fetchSeatData = async (seatData) => {
      try {
        const {Recordset} = await seat.salesSeatList(createPayload(seatData))
        const [data] = Recordset
        setSoldSeat((prev) => [...new Set([...prev, data])])
      } catch (error) {
        console.error('Error SalesSeatList:', error)
      }
    }

    // 각 판매 완료된 좌석에 대해 API 호출
    try {
      await Promise.all(soldSeat.map(fetchSeatData))
    } catch (error) {
      console.error('Error in fetching all seat data:', error)
    }
  }

  // 좌석 리스트
  const getDtryxSeatListApi = async () => {
    const {movie} = formData
    if (movie.MovieCd) {
      const seatData = await seat.seatList(movie.ScreenCd, movie.PlaySDT, movie.ShowSeq)
      const maxRow = Math.max(...seatData.Recordset.map(seat => Number(seat.LocationRow)))
      const maxCol = Math.max(...seatData.Recordset.map(seat => Number(seat.LocationCol)))
      setSeatMatrix(createSeatMatrix(seatData.Recordset, maxRow, maxCol))
    }
  }


  const createSeatMatrix = (seat, maxRow, maxCol) => {
    const matrix = Array.from({length: maxRow}, () => Array(maxCol).fill(null))
    // 좌석 데이터를 배열에 배치
    seat.forEach(seat => {
      const row = Number(seat.LocationRow) - 1
      const col = Number(seat.LocationCol) - 1
      matrix[row][col] = seat
    })

    return matrix
  }

  const handleInputChange = (event, idx) => {
    const targets = Array.isArray(event.target) ? event.target : [event.target]

    setFormData((prev) => {
      const updatedFormData = {...prev}

      targets.forEach(({name, value, id}) => {
        let updatedValue

        if (name === 'couponCode') {
          let newCouponCode = Array.isArray(prev.couponCode) ? [...prev.couponCode] : []

          if (id !== 'selectPackage') {
            newCouponCode[idx] = {
              id: null,
              code: value.toUpperCase(),
              status: utils.hasKoreanText(value) ? utils.COUPON_CHECK_TYPE.ENG : utils.COUPON_CHECK_TYPE.NONE,
              basicPackage: null
            }
          } else {
            newCouponCode[idx] = {
              ...newCouponCode[idx], basicPackage: value
            }
          }

          updatedValue = newCouponCode
        } else if (name === 'movie') {
          updatedValue = JSON.parse(value)
        } else {
          updatedValue = value
        }

        updatedFormData[name] = updatedValue
      })

      return updatedFormData
    })
  }

  const toggleFullScreen = () => {
    setIsFullScreen(prev => !prev)
  }

  const fullScreenStyle = ({
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    maxWidth: '100vw',
    maxHeight: '100vh',
    background: 'rgb(229,231,235)',
    zIndex: 999
  })

  useEffect(() => {
    setSoldSeat([])
    setSeatFocus(null)
    getDtryxHistoryListApi()
  }, [formData?.date])

  useEffect(() => {
    setSoldSeat([])
    setSeatFocus(null)
    setLoading(true)
    getDtryxSeatListApi().finally(() => setLoading(false))
  }, [formData?.movie?.StartTime])

  useEffect(() => {
    setSeatFocus(null)
    getDtryxSalesSeatListApi()
  }, [seatMatrix])

  return (
    <>
      <Helmet>
        <title>MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황</title>
        <meta name="description" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
        <meta property="og:title" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
        <meta property="og:description" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
      </Helmet>

      <main className="relative mt-4 bg-gray-200">
        <div className="xl:w-[840px] w-full m-auto h-full rounded pb-[70px]">
          <Header isReservation={false}/>

          <ScheduleTable formData={formData} setFormData={setFormData} list={list} handleInputChange={handleInputChange} setLoading={setLoading}/>

          {formData?.movie?.MovieNm &&
            <div style={isFullScreen ? fullScreenStyle : {}}>
              <SalesPackageSeat seatMatrix={seatMatrix}
                                soldSeat={soldSeat}
                                seatFocus={seatFocus}
                                setSeatFocus={setSeatFocus}
                                loading={loading}
                                isFullScreen={isFullScreen}
                                cell={cell}
                                toggleFullScreen={toggleFullScreen}/>

              {isFullScreen && <div className={'mb-3'}/>}

              {soldSeat.length !== 0 &&
                <OrderState cell={cell}
                            soldSeat={soldSeat}
                            seatFocus={seatFocus}
                            loading={loading}
                            setSeatFocus={setSeatFocus}
                            isFullScreen={isFullScreen}
                />
              }
            </div>
          }
        </div>

        <Footer/>
      </main>
    </>
  )
}