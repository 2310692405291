import React from 'react'
import { MdCalendarMonth } from 'react-icons/md'

const DatePickerInput = React.forwardRef(({value, onClick, disabled, readOnly, buttonOnly, ...props}, ref) => {
  return (
    <div className="relative" onClick={onClick}>
      <label htmlFor="Search" className="sr-only"> Search </label>

      <input
        value={value || ''} readOnly
        name="date"
        required
        className="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm text-xs"
      />

      <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
        <button type="button" className="text-gray-600 hover:text-gray-700">
          <MdCalendarMonth size={16}/>
        </button>
      </span>
    </div>
  )
})

export default DatePickerInput

