import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Reservation } from '../pages/reservation/Reservation'
import { Sales } from '../pages/sales/Sales'
import { NotFound } from '../pages/notFound/NotFound'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Reservation />
  },
  {
    path: '/sales',
    element: <Sales />
  },
  {
    path: '*',
    element: <NotFound />
  }
], {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
});

export default function Router() {
  return (
    <RouterProvider
      router={router}
      future={{ v7_startTransition: true }}
    />
  );
}
