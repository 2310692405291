import StyledContainer from '../../../components/StyledContainer'
import React from 'react'

export const Name = ({handleInputChange}) => {
  return (
    <StyledContainer>
      <label htmlFor="name" className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
        이름
      </label>

      <input
        type="text"
        id="name"
        name="name"
        onChange={handleInputChange}
        placeholder={'이름을 입력해주세요.'}
        required
        className="mt-3 px-2 w-full border-0 border-b border-gray-200 focus:border-black focus:outline-none focus:ring-0 bg-white text-sm text-gray-700 shadow-sm transition ease-linear"
      />
    </StyledContainer>
  )
}