import React from 'react'

export const Header = ({isReservation = true}) => {
  return (
    <header className="mx-4 bg-white rounded">
      <div className="w-full h-1.5 bg-black"/>
      <div className="flex flex-col justify-center items-center p-6">
        <img
          alt=""
          src="/img/logo.png"
          className="w-64"
        />
        <p className="mt-4 leading-relaxed text-center text-gray-500">
          {isReservation ? '관람권 쿠폰 등록' : '좌석별 패키지 판매 현황'}
        </p>
      </div>
    </header>
  )
}