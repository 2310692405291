import ajax from './ajax'
import { format } from 'date-fns'
import ko from 'date-fns/locale/ko'

export const slack = {
  postNotify(payload) {
    const link = process.env.REACT_APP_AIRTABLE_USED_COUPON_URL
    const slackWebHooksApiUrl = process.env.REACT_APP_SLACK_WEBHOOKS_API_URL

    const getFormattedMovieDate = (date) => {
      const utc = new Date(date)
      const koreaTimeDiff = 9 * 60 * 60 * 1000
      return format(new Date(utc - koreaTimeDiff), 'yyyy.MM.dd(eee) HH:mm', {locale: ko})
    }

    const getSlackTimestamp = () => {
      const now = new Date()
      const seconds = Math.floor(now.getTime() / 1000) // 초 단위 timestamp
      const micros = (now.getMilliseconds() * 1000).toString().padStart(6, '0') // 밀리초를 6자리로 패딩
      return `${seconds}.${micros}`
    }

    // 각 payload에 대한 field 생성
    const fields = payload.map(({Code, Gender, Name, Contact, Movie, Package, Date:date}, idx) => ({
      title: payload.length > 1 ? `${idx+1})` : '',
      value: `이름 : ${Name} (${Gender})\n번호 : ${Contact}\n날짜 : ${getFormattedMovieDate(date)}\n영화 : ${Movie}\n패키지 : ${Package}\n쿠폰 : ${Code}`,
      short: false,
    }))

    const attachments = {
      channel: '라이즈-쿠폰-등록-알림',
      username: 'RYSE-Reservation',
      text: `:ticket:  <${link}|쿠폰 내역이 새로 등록됐습니다.>`,
      attachments: [
        {
          mrkdwn_in: ['text'],
          color: '#006bf8',
          fields,
          ts: getSlackTimestamp()
        }
      ]
    }


    return ajax.post(slackWebHooksApiUrl, JSON.stringify(attachments), null, null)
  }
}