import StyledContainer from '../../../components/StyledContainer'
import React from 'react'

export const Contact = ({formData, handleInputChange}) => {
  const onInputPhone = (e) => {
    e.target.value = e.target.value
    .replace(/[^0-9]/g, '')
    .replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3')
  }

  return (
    <StyledContainer>
      <label htmlFor="Email" className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
        핸드폰 번호
      </label>

      <input
        type="text"
        id="phoneNumber"
        name="phoneNumber"
        value={formData?.phoneNumber}
        onChange={handleInputChange}
        placeholder={'번호를 입력해주세요.'}
        onInput={onInputPhone}
        maxLength="13"
        required
        className={`mt-3 px-2 w-full border-0 border-b border-gray-200 focus:border-black focus:outline-none focus:ring-0 bg-white text-sm text-gray-700 shadow-sm transition ease-linear`}
      />
    </StyledContainer>
  )
}